import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/www/src/templates/mdx-page-layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "online-lessons"
    }}>{`Online Lessons`}</h1>
    <p>{`Learning Tai Chi is a personal journey. It’s difficult to learn without an experienced mentor helping you along the path, but not impossible. If you don’t have access to a teacher, or if you can’t dedicate the time to attend regular classes, these online lessons are here to help.`}</p>
    <p>{`Without a teacher to help, you’ll need to develop a good awareness of your body in order to correct your form. You’ll also need to rely on your own self-judgement, and the recommendation of your doctor, to know what you should and should not do. None of these exercises should cause pain more than a mild stretch would. If you experience pain during any of these exercises, you should stop and consult your doctor. If you undertake this online course, `}<strong parentName="p">{`you acknowledge that you are taking responsibility for your own health`}</strong>{`.`}</p>
    <h2 {...{
      "id": "tai-chi-basics"
    }}>{`Tai Chi Basics`}</h2>
    <p>{`A series of lessons designed to introduce you to the basic principles of Tai Chi.`}</p>
    <p>{`Each lesson will consist of a few exercises designed to increase your strength and flexibility, followed by a Tai Chi movement.`}</p>
    <p>{`Later, the movements will be linked together into a sequence known as a Form.`}</p>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/lessons/lesson-1/"
        }}>{`Lesson 1: Up and Down`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/lessons/lesson-2/"
        }}>{`Lesson 2: Left and Right`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/lessons/lesson-3/"
        }}>{`Lesson 3: Forward`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/lessons/lesson-4/"
        }}>{`Lesson 4: Backward`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "/lessons/lesson-5/"
        }}>{`Lesson 5: Linking`}</a></li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      